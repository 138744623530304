import React, { Component } from "react";

class TrustpilotPlugin extends Component {
  constructor(props) {
    super(props);
    this.state = { id: this.makeid() };
  }

  makeid() {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < 10; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  componentDidMount() {
    const aScript = document.createElement("script");
    aScript.type = "text/javascript";
    aScript.src =
      "//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js";
    // aScript.async = "true"
    document.head.appendChild(aScript);
    aScript.onload = () => {
      setTimeout(() => {
        const trustboxes = [].slice.call(
          document.getElementsByClassName("trustpilot-widget")
        );
        trustboxes.map(box => window?.Trustpilot?.loadFromElement(box));
      }, 1);
    };
  }

  render() {
    const {
      templateId,
      businessUnitId,
      height,
      width,
      theme,
      tags,
      stars
    } = this.props;
    return (
      <>
        <div
          className="trustpilot-widget"
          data-locale="en-GB"
          data-template-id={templateId}
          data-businessunit-id={businessUnitId}
          data-style-height={height}
          data-style-width={width}
          data-theme={theme}
          data-tags={tags}
          data-stars={stars}
          data-review-languages="en"
        />
      </>
    );
  }
}

const TrustpilotPluginComponent = props => {
  return <TrustpilotPlugin {...props} />;
};

export default TrustpilotPluginComponent;
